import './All.scss';

import React from 'react';
import { Query } from 'react-apollo';
import { partition, sortBy } from 'lodash-es';

import * as Core from 'components/core';
import { FONT_FAMILIES_QUERY } from './fontFamiliesQuery';
import { FontFamiliesQuery } from 'client/graphql/types/operations';
import { RouteComponentProps } from '@reach/router';

import Preview from './Preview';
import FamiliesLink from './FamiliesLink';

interface Props extends RouteComponentProps {}

const FamiliesAll: React.FC<Props> = () => {
  return (
    <div className="FamiliesAll">
      <Query<FontFamiliesQuery> query={FONT_FAMILIES_QUERY}>
        {({ loading, data }) => {
          if (loading) {
            return <Core.Loader />;
          }

          if (data == null) {
            return null;
          }

          const [families, additionalFamilies] = partition(
            sortBy(data.families, ['position']),
            (family) => family.status != 'wip',
          );

          return (
            <div className="FamiliesAll-list">
              {families.map((family) => (
                <Preview key={family.id} family={family} />
              ))}

              {additionalFamilies.length > 0 ? (
                <>
                  <FamiliesLink
                    families={additionalFamilies}
                    link="/families/work-in-progress"
                    buttonText="View Fonts in Progress &rarr;"
                  />

                  {families.length % 2 === 0 && (
                    <div className="FamiliesAll-familyPlaceholder" />
                  )}
                </>
              ) : (
                <>
                  {families.length % 2 !== 0 && (
                    <div className="FamiliesAll-familyPlaceholder" />
                  )}
                </>
              )}
            </div>
          );
        }}
      </Query>
    </div>
  );
};

export default FamiliesAll;
