import './Home.scss';

import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { sortBy } from 'lodash-es';
import { Link, RouteComponentProps } from '@reach/router';

import * as Core from 'components/core';

import { HomePageQuery } from 'client/graphql/types/operations';
import Preview from 'components/families/Preview';
import FamiliesLink from 'components/families/FamiliesLink';
import ArticleIndexRow from 'components/blog/IndexRow';
import HomePageMasthead from 'components/home-page/HomePageMasthead';
import UsageExampleImage from 'components/usage-examples/Image';

const HOME_PAGE_QUERY = gql`
  query HomePageQuery {
    homePageFeatures {
      category
      categoryUrl
      caption
      captionUrl
      image1Url {
        small
        medium
        large
      }
      image2Url {
        small
        medium
        large
      }
      image3Url {
        small
        medium
        large
      }
    }

    families {
      id
      slug
      name
      position
      availableAt
      status
      onHomePage
      foregroundColor
      accentColor

      previews {
        id
        position
        textTransform
        letterSpacing
        lineHeight
        fontSizeScale
        hasOverrideFont
        overrideCssClassName

        face {
          id
        }
      }

      faces {
        id
        description
        variant
        size
        width
        weight
        style
        displayOrder
        cssClassName

        family {
          id
          name
        }
      }
    }

    latestArticle {
      id
      title
      slug
      publishedAt
      excerpt
      tags
      bannerImageUrl
      longForm
      author
    }

    projects(limit: 3) {
      id
      name
      slug
      imageUrl

      captions {
        id
        label
        content
      }
    }

    usageExamples(limit: 3) {
      id
      title
      slug
      published

      preview {
        w200
        w400
        w800
        w1600
        w3200
      }
    }
  }
`;

interface Props extends RouteComponentProps {}

const Home: React.FC<Props> = () => {
  return (
    <Query<HomePageQuery> query={HOME_PAGE_QUERY}>
      {({ loading, data }) => {
        if (loading) {
          return <Core.Loader />;
        }

        if (data == null) {
          return null;
        }

        const allFamilies = sortBy(data.families, ['position']);

        const homePageFamilies = allFamilies
          .filter((family) => family.onHomePage)
          .slice(0, 5);

        return (
          <div>
            <HomePageMasthead features={data.homePageFeatures} />

            <div className="Home-about">
              <div className="Home-aboutDescription">
                Frere-Jones Type is an award winning, independent type design
                practice in New York City, creating original typefaces for
                retail licensing and custom clients.
              </div>
              <Link to="/about" className="Home-aboutLink">
                More About Us
              </Link>
            </div>

            <div>
              <div className="Home-familiesList">
                {homePageFamilies.map((family) => (
                  <Preview key={family.id} family={family} />
                ))}

                <FamiliesLink
                  families={allFamilies}
                  link="/families"
                  buttonText="View All Retail Fonts &rarr;"
                />

                {homePageFamilies.length % 2 === 0 && (
                  <div className="Home-familyPlaceholder" />
                )}
              </div>
            </div>

            <div className="Home-blogSection">
              <Core.SecondaryHeading>Latest on the Blog</Core.SecondaryHeading>
              {data.latestArticle && (
                <ArticleIndexRow article={data.latestArticle} />
              )}
            </div>

            <div className="Home-section">
              <Core.SecondaryHeading>
                <Link className="Home-headingLink" to="/custom">
                  Recent Custom Work
                </Link>
              </Core.SecondaryHeading>
              <div className="Home-featuredItems">
                {data.projects.map((project) => (
                  <Link
                    to={`/custom#${project.slug}`}
                    key={project.id}
                    className="Home-project"
                  >
                    {project.imageUrl != null && (
                      <img
                        className="CustomProject-image"
                        src={project.imageUrl}
                        alt={project.name}
                      />
                    )}
                  </Link>
                ))}
              </div>
            </div>

            <div className="Home-section">
              <Core.SecondaryHeading>
                <Link className="Home-headingLink" to="/in-use">
                  Recent In Use Examples
                </Link>
              </Core.SecondaryHeading>
              <div className="Home-featuredItems">
                {data.usageExamples.map((usageExample) => (
                  <Link
                    to={`/in-use/${usageExample.slug}`}
                    key={usageExample.id}
                  >
                    <div className="Home-usageExample">
                      <UsageExampleImage
                        widths={usageExample.preview}
                        sizes={[
                          '(max-width: 884px) 80vw',
                          '(max-width: 1407px) 45vw',
                          '(max-width: 1807px) 30vw',
                          '(max-width: 2207px) 20vw',
                          '15vw',
                        ]}
                      />
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        );
      }}
    </Query>
  );
};

export default Home;
