import './FamiliesLink.scss';

import React, { useCallback, useState } from 'react';
import { Link } from '@reach/router';

interface Props {
  families: { id: number; accentColor: string }[];
  link: string;
  buttonText: string;
}

const FamiliesLink: React.FC<Props> = (props) => {
  const { families, link, buttonText } = props;

  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = useCallback(() => {
    setHovered(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setHovered(false);
  }, []);

  function fillToSixteen<T>(families: T[]): T[] {
    const targetLength = 16;

    if (families.length >= targetLength) {
      return families.slice(0, targetLength);
    }

    const result = [];
    let i = 0;

    while (result.length < targetLength) {
      result.push(families[i % families.length]);
      i++;
    }

    return result;
  }

  const truncatedFamilies = fillToSixteen(families);

  return (
    <Link
      to={link}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="FamiliesLink"
    >
      <div className="FamiliesLink-tiles">
        {truncatedFamilies.map((family) => (
          <div
            className="FamiliesLink-tile"
            key={family.id}
            style={hovered ? { backgroundColor: `#${family.accentColor}` } : {}}
          />
        ))}
      </div>
      <div className="FamiliesLink-labelContainer">
        <div className="FamiliesLink-label">
          <div>{buttonText}</div>
        </div>
      </div>
    </Link>
  );
};

export default FamiliesLink;
