import { put, call } from 'redux-saga/effects';
import gql from 'graphql-tag';
import * as Sentry from '@sentry/react';

import apolloClient from 'apolloClient';
import initialState from './initialState';
import { State } from './types';
import { sortFontFaces, sortFontPackages } from 'utils/sorting';
import { FontFamilyAndLicenseOptionsQuery } from 'client/graphql/types/operations';

// TODO: Maybe move the query closer to the components that use this data?
const FONT_FAMILY_AND_LICENSE_OPTIONS = gql`
  query FontFamilyAndLicenseOptionsQuery($slug: String!) {
    family(slug: $slug) {
      id
      slug
      name
      headingLetterSpacing
      headingTextTransform
      headingFontSizeScale
      foregroundColor
      accentColor
      accentColorTint60
      accentColorTint50
      accentColorTint25
      accentColorTint20
      accentColorTint15
      logoBackgroundColor
      description
      designCredit
      supportedLanguages
      completePrice
      fontFacePrice
      discountedFontFacePrice
      completePackageDescription
      minTesterFontSize
      maxTesterFontSize
      minTesterTracking
      maxTesterTracking
      featuresDeclaration
      specimenDocumentUrl
      specimenPreviewUrl
      articleImageUrl
      hasTrialArchives
      status

      headingFace {
        id
        cssClassName
      }

      faces {
        id
        variant
        size
        weight
        width
        style
        shortDescription
        description
        previewUrl
        retinaPreviewUrl
        cssClassName
        hasFile

        family {
          id
          name
        }
      }

      packages {
        id
        name
        variant
        size
        width
        price
        discountedPrice
        description
      }

      features {
        id
        name
        characterCodes
        caption
      }

      typeTesters {
        id
        position
        faceId
        content
        size
        lineHeight
        tracking
        textAlign
        scaling

        features {
          name
          tag
          enabledByDefault
        }
      }

      article {
        id
        slug
        title
        author
      }
    }

    licenseOptions {
      id
      kind
      multiplier
      description
      suffix
    }
  }
`;

export type LoadData = {
  type: 'FONT_LICENSE/LOAD_DATA';
  payload: {
    slug: string;
  };
};

export type LoadDataSuccess = {
  type: 'FONT_LICENSE/LOAD_DATA/SUCCESS';
  payload: { slug: string } & FontFamilyAndLicenseOptionsQuery;
};

export function action(slug: string): LoadData {
  return {
    type: 'FONT_LICENSE/LOAD_DATA',
    payload: { slug },
  };
}

export function reducer(
  state: State,
  action: LoadData | LoadDataSuccess,
): State {
  switch (action.type) {
    case 'FONT_LICENSE/LOAD_DATA': {
      return initialState;
    }

    case 'FONT_LICENSE/LOAD_DATA/SUCCESS': {
      const { family, licenseOptions } = action.payload;

      if (family == null) {
        return state;
      }

      const desktopOption = licenseOptions.find(
        (option) => option.kind === 'desktop',
      );

      if (desktopOption == null) {
        return state;
      }

      const license = {
        faceIds: [],
        licenseOptionIds: {
          desktop: null,
          web: null,
          mobile: null,
        },
        familyHighlighted: false,
        highlightedPackage: null,
      };

      return {
        ...state,
        family: {
          ...family,
          faces: sortFontFaces(family.faces),
          packages: sortFontPackages(family.packages),
        },
        license,
        licenseOptions,
      };
    }
  }

  return state;
}

export function* saga(action: LoadData) {
  const response: FontFamilyAndLicenseOptionsQuery = yield call(
    request,
    action.payload.slug,
  );

  yield put({
    type: 'FONT_LICENSE/LOAD_DATA/SUCCESS',
    payload: {
      slug: action.payload.slug,
      ...response,
    },
  } as LoadDataSuccess);
}

function request(
  slug: string,
): Promise<{ data: FontFamilyAndLicenseOptionsQuery }> {
  return apolloClient
    .query({
      query: FONT_FAMILY_AND_LICENSE_OPTIONS,
      variables: { slug },
    })
    .then(
      (response) => response.data,
      (error) => {
        Sentry.captureException(error);

        throw error;
      },
    );
}
