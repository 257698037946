import gql from 'graphql-tag';

export const FONT_FAMILIES_QUERY = gql`
  query FontFamiliesQuery {
    families {
      id
      slug
      name
      position
      availableAt
      status
      foregroundColor
      accentColor

      previews {
        id
        position
        textTransform
        letterSpacing
        lineHeight
        fontSizeScale
        hasOverrideFont
        overrideCssClassName

        face {
          id
        }
      }

      faces {
        id
        description
        variant
        size
        width
        weight
        style
        displayOrder
        cssClassName

        family {
          id
          name
        }
      }
    }
  }
`;
